



















export default {
  name: "InfoMessage",
  props: {value: Boolean, title: String, msg: String},
  data() {
      return {
          imgTitle: require('@/assets/verify.png')
      }
  },
  computed: {
      show: {
          get() {
              return this.value;
          },
          set(value) {
              this.$emit("input", value);
          }
      }
  }
    
};