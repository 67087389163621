var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto bg-timber", attrs: { dark: "" } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-img", { attrs: { src: _vm.imgTitle, width: "24px" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.title))])
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.msg))]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", block: "" },
                      on: {
                        click: function($event) {
                          _vm.show = false
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }